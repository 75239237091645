import React, { memo } from 'react';
import LandingSection from './landingSection/LandingSection';
import './css/HomePage.css';
import HomePageOther from './homePageOther/HomePageOther';

const HomePage = () => {
    return (
        <div className="home-page-container">
            <LandingSection />
            <HomePageOther />
        </div>
    );
};

export default (memo(HomePage));
