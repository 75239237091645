import React, { memo } from 'react';
import './css/LandingSection.css';
import OptimXHalfIcon from '../../../assets/icons/optimxHalfIcon.png';
import OptimXWebservicesNameIcon from '../../../assets/icons/optimxWebservicesNameIcon.png';
import OptimXTechnologiesNameIcon from '../../../assets/icons/optimxTechnologiesNameIcon.png';
import Button from '../../uiElements/buttons/button1/Button1';
import { isOptimXTechnologies } from '../../../catalog/Others';

const LandingSection = () => {
    return (
        <div id="home" className="landing-section-container">
            <div className="p-l-15 text-container font-size-2xl">
                {isOptimXTechnologies
                    ? <img src={OptimXTechnologiesNameIcon} className="icon x-center" alt="OptimX Technologies" />
                    : <img src={OptimXWebservicesNameIcon} className="icon x-center" alt="OptimX Webservices" />}

                <h1 className="m-t-10px">WEB SOLUTIONS</h1>
                <Button
                    onClick={() => { window.location.replace('/#contact'); }}
                    title="Contact Us"
                    type="button"
                />
            </div>
            <div className="icon-container">
                <img src={OptimXHalfIcon} className="icon x-center" alt="OptimX Webservices" />
            </div>
        </div>
    );
};

export default (memo(LandingSection));
