import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { isOptimXTechnologies } from '../catalog/Others';
import HomePage from '../components/homePage/HomePage';
import PrivacyPolicy from '../components/privacyPolicy/PrivacyPolicy';
import TeamPrivacyPolicy from '../components/teamPrivacyPolicy/TeamPrivacyPolicy';
import TermsOfService from '../components/termsOfService/TermsOfService';
import Footer from '../components/uiElements/footer/Footer';
import NavBar from '../components/uiElements/navBar/NavBar';
import ScrollToTop from '../components/uiElements/scrollToTop/ScrollToTop';

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <main>
                <ScrollToTop>
                    <Switch>
                        <Route path="/" exact render={() => { return <HomePage />; }} />
                        {isOptimXTechnologies ? (
                            <>
                                <Route path="/teamprivacypolicy" exact render={() => { return <TeamPrivacyPolicy />; }} />
                                <Route path="/privacypolicy" exact render={() => { return <PrivacyPolicy />; }} />
                                <Route path="/termsofservice" exact render={() => { return <TermsOfService />; }} />
                            </>
                        ) : null}
                        <Route render={() => { return <Redirect to={{ pathname: '/' }} />; }} />
                    </Switch>
                </ScrollToTop>
            </main>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
