/* eslint-disable react/button-has-type */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/Button2.css';

const Button2 = (props) => {
    const { onClick, title, type } = props;

    return (
        <button
            className="button-container-2 m-l-10px m-r-10px"
            onClick={onClick}
            type={type}
        >
            {title}
        </button>
    );
};

Button2.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
};

Button2.defaultProps = {
    onClick: null,
    type: 'button',
};

export default memo(Button2);
