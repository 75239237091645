import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ScrollToTop = (props) => {
    const { children } = props;
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return <>{children}</>;
};

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(ScrollToTop);
