import React, { memo } from 'react';

const TeamPrivacyPolicy = () => {
    return (
        <div className="p-t-50px m-l-50px m-r-50px m-b-50px">
            <h1>Privacy Policy</h1>
            <p>Last Updated on 12-22-21. This privacy policy is effective immediately.</p>
            <p>This Privacy Policy applies to the websites (“Subscription Services”) managed and administered by the Authorized User (“Account Holder”) of the Subscription Services specific to this link. This Policy describes how Account Holders of Subscription Services using this Software may use and collect personal information which users provide through the use of the Subscription Services. </p>
            <h2>PERSONAL INFORMATION COLLECTED</h2>
            <p>In some scenarios, the Account Holder of the Subscription Services collects information that you provide. For example, if a user submits information in a form on a website and provides contact information such as an email address, that information the user voluntarily provided is collected. Other examples where information may be collected depends upon how you use the Subscription Services. In other scenarios, the Account Holder may collect:</p>
            <ul>
                <li>Contact information voluntarily provided</li>
                <li>Roster information for teams</li>
                <li>Team information associated with your organization submitted, including game schedules, player statistics, attendance and match scores</li>
                <li>Electronic messages you send or receive via using the subscription services</li>
                <li>Messages and feedback received and sent to the Account Holder of the Subscription Services; and </li>
                <li>Other forms of team information including, photo galleries, articles, and other uploaded content</li>
            </ul>
            <h2>INFORMATION USAGE</h2>
            <p>Account Holder may use information provided to: </p>
            <ul>
                <li>Provide the Subscription Services </li>
                <li>To prevent fraud activity and in some situations, protect legal rights </li>
                <li>Specifically for any purpose in which it was provided or volunteered </li>
                <li>To send you information </li>
                <li>To comply with local and/or applicable law</li>
            </ul>
            <h2>DEVICE INFORMATION COLLECTED</h2>
            <p>When using the Subscription Services the Account Holder may also collect information from devices of users. This information may include:</p>
            <ul>
                <li>IP Address associated with device</li>
                <li>Operating System </li>
                <li>Browser Type</li>
                <li>Browser Settings</li>
                <li>In some instances, Social Media information may be collected if you engage through social media with the Subscription Services through Facebook, as an example</li>
            </ul>
            <h2>INFORMATION DISCLOSED</h2>
            <p>The Account Holder may share your information with:</p>
            <ul>
                <li>Law Enforcement Agencies or Regulators</li>
                <li>Services/Providers that provide performance or business related functionality</li>
            </ul>
            <h2>OPTIMX SPORTS</h2>
            <p>The Subscription Services utilized by Account Holders are powered by OptimX Technologies, doing business as OptimX Sports. Depending on how Account Holders use the Subscription Services, OptimX Sports collects information from users as established in the OptimX Technologies Privacy Policy.</p>
            <ul>
                <li>For users subscribed and logged into the Subscription Services, OptimX Sports may collect: </li>
                <li>Aggregate use data for analytical purposes</li>
                <li>Electronic messages sent to or received in the OptimX Sports account; </li>
                <li>Contact information voluntarily provided</li>
                <li>Registration information such as name, email address, and other relevant information needed to register an OptimX Sports account</li>
                <li>Information provided when reaching out to OptimX Sports personnel. </li>
            </ul>
            <h2>YOUR RIGHTS & CHOICES</h2>
            <p>You may send the Account Holder an e-mail via the Contact Us  link to request access to, correct or delete any personal information that you have provided. We may not accommodate a request to change access, or delete information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. To execute your Data Rights, please contact the Account Holder.</p>
            <h2>CONTACT</h2>
            <p>Any questions relating to this website of OptimX Technologies should be referred to the contact information of the Account Holder of this Subscription Service Account. </p>
        </div>
    );
};

export default (memo(TeamPrivacyPolicy));
