import React, { memo } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import './css/Footer.css';
import OptimXIcon from '../../../assets/icons/optimxIcon.png';
import Button2 from '../buttons/button2/Button2';
import { isOptimXTechnologies } from '../../../catalog/Others';

const Footer = () => {
    const history = useHistory();

    return (
        <div className="footer">
            {isOptimXTechnologies
                ? (
                    <NavLink className="icon-container" to="/" exact>
                        <img src={OptimXIcon} className="icon" alt="OptimX Technologies" />
                    </NavLink>
                )
                : (
                    <NavLink className="icon-container" to="/" exact>
                        <img src={OptimXIcon} className="icon" alt="OptimX Webservices" />
                    </NavLink>
                )}
            <div className="buttons">
                {/* <Button2
                    onClick={() => { window.location.replace('/#about'); }}
                    title="About"
                    type="button"
                />
                <Button2
                    onClick={() => { window.location.replace('/#contact'); }}
                    title="Contact"
                    type="button"
                /> */}
                <Button2
                    onClick={() => { history.push('/privacypolicy'); }}
                    title="Privacy Policy"
                    type="button"
                />
                <Button2
                    onClick={() => { history.push('/termsofservice'); }}
                    title="Terms and Services"
                    type="button"
                />
            </div>
        </div>
    );
};

export default (memo(Footer));
