/* eslint-disable react/button-has-type */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/Button1.css';

const Button1 = (props) => {
    const { onClick, title, type } = props;

    return (
        <button
            className="button-container-1"
            onClick={onClick}
            type={type}
        >
            {title}
        </button>
    );
};

Button1.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
};

Button1.defaultProps = {
    onClick: null,
    type: 'button',
};

export default memo(Button1);
