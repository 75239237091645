import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import './css/NavBar.css';
import OptimXIcon from '../../../assets/icons/optimxIcon.png';
import OptimXWebservicesNameIcon from '../../../assets/icons/optimxWebservicesNameIcon.png';
import OptimXTechnologiesNameIcon from '../../../assets/icons/optimxTechnologiesNameIcon.png';
import Button2 from '../buttons/button2/Button2';
import { isOptimXTechnologies } from '../../../catalog/Others';

const Header = () => {
    return (
        <header className="navbar">
            <div className="icon-container">
                {isOptimXTechnologies ? (
                    <NavLink className="icon-container" to="/" exact>
                        <img src={OptimXIcon} className="icon m-r-1" alt="OptimX Technologies" />
                        <img src={OptimXTechnologiesNameIcon} className="name-icon m-l-1" alt="OptimX Technologies" />
                    </NavLink>
                ) : (
                    <NavLink className="icon-container" to="/" exact>
                        <img src={OptimXIcon} className="icon m-r-1" alt="OptimX Webservices" />
                        <img src={OptimXWebservicesNameIcon} className="name-icon m-l-1" alt="OptimX Webservices" />
                    </NavLink>
                )}
            </div>
            <div className="buttons">
                <Button2
                    onClick={() => { window.location.replace('/#about'); }}
                    title="About"
                    type="button"
                />
                <Button2
                    onClick={() => { window.location.replace('/#contact'); }}
                    title="Contact"
                    type="button"
                />
            </div>
        </header>
    );
};

export default (memo(Header));
