import React, { memo } from 'react';
import './css/HomePageOther.css';
// import OptimXGreenIcon from '../../../assets/icons/optimxGreenIcon.png';
import OptimXOtherNameIcon from '../../../assets/icons/optimxOtherNameIcon.png';
// import OptimXGrayIcon from '../../../assets/icons/optimxGrayIcon.png';
import HomePage1 from '../../../assets/images/homepage1.png';
import HomePage2 from '../../../assets/images/homepage2.png';
import HomePage3 from '../../../assets/images/homepage3.png';
import HomePage4 from '../../../assets/images/homepage4.png';
import HomePage5 from '../../../assets/images/homepage5.png';
// import Button from '../../uiElements/button/Button';

const LandingSection = () => {
    return (
        <div className="home-page-other-container x-center">
            <div className="before-main-section">
                <img src={OptimXOtherNameIcon} className="optimx-names m-t-5 m-b-5" alt="OptimX" />
            </div>
            <div className="main-section bg-white curve-border-20px x-center m-b-5 box-shadow-black">
                <div className="top-image">
                    {/* <div className="top-image-section bg-white curve-border-20px x-center">
                        <div className="top-image-section-section">
                            <img src={OptimXGrayIcon} className="top-image-section-section-icon p-b-2" alt="OptimX" />
                            <p className="top-image-section-section-text font-spacing-widest line-height-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate erat ante.</p>
                        </div>
                        <div className="top-image-section-section">
                            <img src={OptimXGrayIcon} className="top-image-section-section-icon p-b-2" alt="OptimX" />
                            <p className="top-image-section-section-text font-spacing-widest line-height-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate erat ante.</p>
                        </div>
                        <div className="top-image-section-section">
                            <img src={OptimXGrayIcon} className="top-image-section-section-icon p-b-2" alt="OptimX" />
                            <p className="top-image-section-section-text font-spacing-widest line-height-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate erat ante.</p>
                        </div>
                    </div> */}
                </div>
                <div className="second-top-image">
                    <p className="font-size-5xl font-weight-800 text-uppercase">WEB SOLUTIONS</p>
                </div>
                <div className="other-sections">
                    <div className="text-left">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-10px">Professionally Designed Websites</p>
                        <p className="font-size-xl font-weight-100 m-t-10px">Our talented web design and development team will work closely with your company to understand your vision and tailor a professionally designed website that is both memorable and results-driven. No matter if your business is just launching or needs a refresh, we’re here to help you succeed.</p>
                    </div>
                    <img src={HomePage1} className="image" alt="OptimX" />
                </div>
                <div className="other-sections other-sections-reverse">
                    <div className="text-right">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-10px">Increased Web Visibility</p>
                        <p className="font-size-xl font-weight-100 m-t-10px">Our web service solution can also be complemented by our SEO optimization services for a truly comprehensive marketing provision. From optimized landing pages and social media advertisements to e-commerce that seamlessly integrates with your business, Optimx Web Services can create just that. We are here to optimize your website&#39;s online visibility to its maximum potential. </p>
                    </div>
                    <img src={HomePage2} className="image" alt="OptimX" />
                </div>
                <div className="other-sections">
                    <div className="text-left">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-10px">Website Management </p>
                        <p className="font-size-xl font-weight-100 m-t-10px">Online brands and identities, be it for a company, online application or a personal website need domain names, security certificates, hosting and web solutions to transact online. Those same businesses also need a trusted partner to help manage them, and ensure their online businesses remain operational. With Optimx Web Services, we provide both.</p>
                    </div>
                    <img src={HomePage3} className="image" alt="OptimX" />
                </div>
                <div className="other-sections other-sections-reverse">
                    <div className="text-right">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-10px">Hosting Services</p>
                        <p className="font-size-xl font-weight-100 m-t-10px">Hosting optimized for your company&#39;s online brand. Spin up your next site simply with our solution built specifically for your business needs. Manage sites on a powerful, user-friendly platform on a budget-friendly hosting system.</p>
                    </div>
                    <img src={HomePage4} className="image" alt="OptimX" />
                </div>
                <div id="about" className="other-sections other-sections-only-text x-center">
                    <div className="text-left">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-10px">About Us</p>
                        <p className="font-size-xl font-weight-100 m-t-10px">
                            At Optimx Web Services, we know how important it is for our clients to have a website that perfectly conveys their brand in order to both attract the desired target audience and maintain their reputation. With this in mind, we endeavour to create website designs that project your vision following a thorough consultation process to ensure that your website is truly bespoke. Optimx Web Services is dedicated to creating the most professional, functional, and unique websites that reflect client vision and drive new business. And, we do all that at affordable prices.
                        </p>
                        <p className="font-size-xl font-weight-100 m-t-10px">
                            To ensure that your website evolves and grows seamlessly in an age of near constant digital development, our team can ensure that your website benefits from all of the latest design and functionality to ensure that it continues to convert business at the optimum level.
                        </p>
                    </div>
                </div>
                <div id="contact" className="other-sections m-b-3">
                    <div className="text-left">
                        <p className="font-size-4xl font-weight-400 text-uppercase m-b-30px x-center">Schedule a meeting</p>
                        <div className="x-center">
                            <a href="mailto:jon@optimxtechnologies.com" className="link text-no-underline">
                                <p className="text font-size-xl font-weight-100 m-t-10px bg-gray text-white p-t-2 p-l-2 p-r-2 p-b-2">contact@optimxtechnologies.com</p>
                            </a>
                            <a href="tel:+13177480245" className="link text-no-underline">
                                <p className="text font-size-xl font-weight-100 m-t-10px bg-gray text-white p-t-2 p-l-2 p-r-2 p-b-2">+1 (317) 748-0245</p>
                            </a>
                        </div>
                    </div>
                    <img src={HomePage5} className="image" alt="OptimX" />
                </div>
            </div>
            <div className="second-bottom-section">
                <img src={OptimXOtherNameIcon} className="optimx-names m-t-5 m-b-5" alt="OptimX" />
            </div>
        </div>
    );
};

export default (memo(LandingSection));
